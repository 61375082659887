import React, { useEffect } from "react";
import { useTestInvitationsQuery } from "@/generated/graphql";
import { useApiErrorHandler } from "@/features/error";
import { ApplicationList, TApplicationListItem } from "@/features/application";
import styled from "@emotion/styled";
import { device } from "@/styles/breakpoints";

export function ApplicationsInvited({}) {
  const handleError = useApiErrorHandler();
  const [{ data, fetching, error }] = useTestInvitationsQuery();

  useEffect(() => {
    if (!error) return;
    handleError(error);
  }, [handleError, error]);

  if (!data?.testInvitations) {
    return null;
  }

  return (
    <Container>
      <Title>New Job invites</Title>
      <Subtitle>
        Find the latest job recommendations matching your skills.
      </Subtitle>
      <ApplicationList
        applications={data?.testInvitations as TApplicationListItem[]}
      />
    </Container>
  );
}

const Container = styled.div`
  margin-bottom: 24px;
`;
const Title = styled.div`
  font-weight: 700;
  font-size: 22px;
  color: ${(props) => props.theme.typography.colorPrimary};
  margin-bottom: 8px;

  @media ${device.tablet} {
    font-size: 20px;
    margin-bottom: 16px;
  }
`;

const Subtitle = styled.div`
  font-weight: 400;
  font-size: 14px;
  color: ${(props) => props.theme.colors.purple[70]};
  margin-bottom: 16px;

  @media ${device.tablet} {
    display: none;
  }
`;
