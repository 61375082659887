import { useEffect } from "react";
import { useMedia } from "react-use";
import styled from "@emotion/styled";
import { device } from "@/styles/breakpoints";
import { LockedPage } from "@/features/auth";
import { useAmplitude } from "@/features/analytics";
import { PageContent, PageTitle } from "@/features/common";
import { ApplicationsActive, ApplicationsPast } from "@/features/application";
import { SkillProfile } from "@/features/skill";
import { ApplicationsInvited } from "@/features/application/components/applications-invited";
import { JobOfferBanner } from "@/features/job-offer";

export default function Home() {
  const isTablet = useMedia(device.tablet);
  const { logEvent } = useAmplitude();

  useEffect(() => logEvent("page view/dashboard"), [logEvent]);

  return (
    <LockedPage>
      <PageTitle title="Dashboard" />
      <PageContent>
        <JobOfferBanner />
        <ApplicationsInvited />
        <ApplicationsActive />
        {!isTablet && <ApplicationsPast />}
        <SkillProfileContainer>
          <SkillProfile />
        </SkillProfileContainer>
      </PageContent>
    </LockedPage>
  );
}

const SkillProfileContainer = styled.div`
  margin-top: 24px;
`;
